import * as React from "react";
import { WhatsNewRelationshipComponent } from "../../components/WhatsNewRelationShip";
import { IWhatsNewRelationship } from "@types";

type WnSectionContainerProps = {
  isFeaturesUpdates?: boolean;
  welcomePageFeatures: IWhatsNewRelationship[];
};

const WnSectionContainer: React.FC<WnSectionContainerProps> = ({
  welcomePageFeatures,
  isFeaturesUpdates = false,
}) => {
  const cards = welcomePageFeatures.map((card, index) =>
    WhatsNewRelationshipComponent(
      index,
      card.title,
      card.whatsNewC.whatsNewSnippet,
      card.uri,
      card.whatsNewC?.whatsNewLink,
      card.whatsNewC?.whatsNewImage
    )
  );
  return (
    <section
      className={`${
        isFeaturesUpdates ? "pad-top--large" : "pad-bottom--large"
      }`}
    >
      {cards}
    </section>
  );
};

export default WnSectionContainer;
