import * as React from "react";
import { HeroSinglePProps } from "@types";

import PrimaryButtonWithArrow from "../../Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";
import styles from "./AllResourcesBannerPrimary.module.scss";

const AllResourcesBannerPrimary: React.FC<HeroSinglePProps> = ({
  heroSingleSubcopy,
  heroSingleTitle,
  bannerIsShow,
  isPartners = false,
  isFeaturesUpdates = false,
}) => {
  const subCopyPaddingBottom = () => (bannerIsShow || isPartners ? 15 : 0);

  const primaryBannerClasses = `backgroundBlue ${
    !isFeaturesUpdates
      ? styles.featuresPartnersUpdatesBannerContainer
      : styles.featuresUpdatesBannerContainer
  }`;

  return (
    <div className={primaryBannerClasses}>
      <div
        data-testid="div:container:AllResourcesBannerPrimary"
        className={`full-width shorter ${!isPartners && "w--80"}`}
      >
        <div
          className={`text-alignment ${
            isPartners || isFeaturesUpdates
              ? styles.partnersStyle
              : styles.AllResourcesBannerStyle
          } ${isFeaturesUpdates && styles.featuresUpdates}`}
        >
          <h1 data-testid="H1:title" className={styles.bannerTitle}>
            {heroSingleTitle}
          </h1>
          {isPartners ? (
            <p
              data-testid="p:isPartners:AllResourcesBannerPrimary"
              className={styles.bannerSubTitle}
              dangerouslySetInnerHTML={{ __html: heroSingleSubcopy }}
              style={{ paddingBottom: subCopyPaddingBottom() }}
            />
          ) : (
            <p
              data-testid="p:isNotPartners:AllResourcesBannerPrimary"
              className={styles.bannerSubTitle}
            >
              {heroSingleSubcopy}
            </p>
          )}
          {isPartners && (
            <PrimaryButtonWithArrow
              buttonText={"Apply Now"}
              buttonLink={"#partner-form"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllResourcesBannerPrimary;
