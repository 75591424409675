import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import BlueLinkWithArrow from "../Buttons/BlueLinkWithArrow/BlueLinkWithArrow";
import { Image, ImageMediaItemUrl } from "@types";

import styles from "./whatsNewRelationShip.module.scss";

type TWhatsNewImage = Image | ImageMediaItemUrl;

const CardImage: React.FC<{ whatsNewImage: TWhatsNewImage }> = ({
  whatsNewImage,
}) => {
  if (whatsNewImage?.localFile?.childImageSharp) {
    const image = getImage(whatsNewImage?.localFile);
    return <GatsbyImage image={image} alt={whatsNewImage?.altText || ""} />;
  } else {
    return (
      <img
        src={whatsNewImage?.localFile.publicURL}
        alt={whatsNewImage?.altText}
      />
    );
  }
};

export const WhatsNewRelationshipComponent = (
  index,
  title,
  paragraph,
  wnLink,
  link,
  whatsNewImage
) => {
  if (whatsNewImage) {
    return (
      <div key={index} className={`full-width ${styles.featureGrid}`}>
        <div className={styles.featureCopy}>
          <h2 className={`h2 ${styles.articleTitle}`}>{title}</h2>

          <p
            className={`body ${styles.articleParagraph}`}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />

          <p className="body">
            <BlueLinkWithArrow
              link={link ? link : wnLink}
              linkCopy={"Learn more"}
            />
          </p>
        </div>
        <div className={styles.featureImage}>
          <CardImage whatsNewImage={whatsNewImage} />
        </div>
      </div>
    );
  } else {
    return;
  }
};
