import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import DownArrow from "../SVGIcons/DownArrow/DownArrow";
import styles from "./ResourcesMainCard.module.scss";

export const MainBannerComponent = ({
  uri,
  title,
  keywords,
  resourceCta,
  resourceSubcopy,
  resourcesCardsImage,
  badgeCopy,
  externalFeaturedStyles,
}: any) => {
  return (
    <div
      data-testid="div:MainBannerComponent"
      className={`full-width ${styles.cardMainContainer}`}
    >
      <a href={uri} className={styles.cardContainer}>
        <div
          data-testid="Div:Card"
          className={`${styles.resourceFeaturedContainer} ${
            externalFeaturedStyles &&
            externalFeaturedStyles.resourceFeaturedContainer
          }`}
        >
          <div
            className={`${styles.resourceFeaturedDescription} ${
              externalFeaturedStyles &&
              externalFeaturedStyles.resourceFeaturedDescription
            }`}
          >
            <p className={styles.badgeText}>
              <span className={styles.badge}>{badgeCopy}</span>
              {` ${keywords}`}
            </p>
            <h3 className={styles.cardMainTitle}>{title}</h3>
            <p>{resourceSubcopy}</p>
            <button className={styles.cardButton}>{resourceCta}</button>
          </div>
          {resourcesCardsImage && (
            <div
              className={`${styles.containerImage} ${
                externalFeaturedStyles && externalFeaturedStyles.containerImage
              }`}
            >
              <img
                src={resourcesCardsImage}
                alt={resourcesCardsImage?.altText || ""}
              />
            </div>
          )}
        </div>
      </a>
      <a href={uri} className={styles.cardContainerMobile}>
        {resourcesCardsImage && (
          <img
            src={resourcesCardsImage}
            alt={resourcesCardsImage?.altText || ""}
          />
        )}
        <div className={styles.resourcesText}>
          <span>{keywords}</span>
          <h4>{title}</h4>
        </div>
      </a>
    </div>
  );
};

export const ShowOptionalBanner: React.FC<{
  title: string;
}> = ({ title = "" }) => {
  return (
    <div
      data-testid="div:ShowOptionalBanner"
      className={styles.cardActiveSearch}
    >
      <DownArrow cls={styles.icon} />
      {title}
    </div>
  );
};
