/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import AllResourcesBannerPrimary from "../../components/Banners/AllResourcesBannerPrimary/AllResourcesBannerPrimary";
import WnSectionContainer from "../../containers/WnSectionContainer";
import { IWhatsNewRelationship } from "@types";
import { ShowOptionalBanner } from "../../components/ResourcesMainCard/MainBannerComponent";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { deIndexSeo } from "../../utils/deIndexSeo";

type featuresUpdatesTypes = {
  wpPage: {
    seo: YoastSEOType;
  };
  allWpWhatsNewSingle: {
    nodes: IWhatsNewRelationship[];
  };
};

const featuresUpdates: React.FC<PageProps<featuresUpdatesTypes>> = ({
  data,
}) => {
  const { wpPage, allWpWhatsNewSingle } = data;
  const { nodes } = allWpWhatsNewSingle;
  return (
    <MainLayout hasFooterLargeCta={true} backgroundColor="blue">
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <AllResourcesBannerPrimary
        heroSingleSubcopy={"Check out our latest product releases and updates."}
        heroSingleTitle={"What's new at SimplePractice"}
        bannerIsShow={false}
        isFeaturesUpdates
      />
      <ShowOptionalBanner title={"View updates"} />
      <WnSectionContainer welcomePageFeatures={nodes} isFeaturesUpdates />
    </MainLayout>
  );
};

export const query = graphql`
  query WNTemplateQuery($id: String) {
    wpPage(id: { eq: $id }) {
      ...YoastSEOFragment
    }
    allWpWhatsNewSingle(sort: { order: DESC, fields: date }) {
      nodes {
        id
        title
        uri
        slug
        whatsNewC {
          whatsNewLink
          whatsNewSnippet
          whatsNewCustomCtaLabel
          whatsNewContent
          hiddenUpdate
          fieldGroupName
          whatsNewImage {
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 985
                  placeholder: BLURRED
                  formats: [WEBP, JPG, AUTO]
                )
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default featuresUpdates;
